import { render, staticRenderFns } from "./contacts-slider.vue?vue&type=template&id=61f7f1c0&scoped=true&"
import script from "./contacts-slider.vue?vue&type=script&lang=js&"
export * from "./contacts-slider.vue?vue&type=script&lang=js&"
import style0 from "./contacts-slider.vue?vue&type=style&index=0&id=61f7f1c0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f7f1c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureComponent: require('/home/d/didisher2/autovyborg.ru/www/components/picture/picture-component.vue').default})
